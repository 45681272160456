<template>
	<div class="council">
		<SubHeader title="Schedule" subtitle="ベニュー別スケジュール" />

		<div class="pdf_download container">
			<div class="row justify-content-center">
				<div class="col-lg-12">
					<a
						class="btn btn-dl"
						href="/pdf/2024/schedule_2024chuki.pdf"
						target="_blank"
					>
						<i class="fas fa-cloud-download-alt"></i>
						2024年度 中期リーグスケジュール表（PDF版）
					</a>
				</div>
			</div>
		</div>

		<div class="table-responsive">
			<table
				class="table table-bordered table-striped text-center my-5"
				v-if="!isLoading"
			>
				<thead class="thead-dark">
					<tr>
						<th scope="col">Venue</th>
						<th scope="col">Team</th>
						<template v-for="(match_day, index) in match_days">
							<th scope="col" :key="'match_day' + match_day">
								<span class="badge badge-pill badge-light">
									{{ Number(index) + 1 }}
								</span>
								<br />
								{{ match_day }}
							</th>
						</template>
					</tr>
				</thead>
				<tbody>
					<template v-for="team in teams">
						<tr :key="'team_upper' + team.id">
							<th
								class="venue"
								:rowspan="team.team_count_in_venue"
								v-if="team.team_count_in_venue"
							>
								<span class="venue_name">{{ team.venue_name }}</span>
								<br />
								<span class="badge badge-pill badge-secondary">
									{{ team.team_count_in_venue }}
								</span>
							</th>
							<th>
								<span class="badge badge-primary">{{ team.group_name }}</span>
								<br />
								{{ team.name }}
							</th>
							<template v-for="match_day in match_days">
								<td :key="'match' + match_day">
									<div v-if="team.match[match_day] != undefined">
										<span class="team_name">
											{{ team.match[match_day].home }}
										</span>
										<br />
										<span class="badge badge-secondary">vs</span>
										<br />
										<span class="team_name">
											{{ team.match[match_day].away }}
										</span>
									</div>
								</td>
							</template>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
//import Schedule from "@/components/common/Schedule.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

export default {
	name: "schedule",
	components: {
		SubHeader,
	},
	data() {
		return {
			isLoading: true,
			teams: [],
			match_days: [],
		};
	},
	mounted: function () {
		this.getSchedule();
	},
	methods: {
		getSchedule: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {
				/*project_id: localStorage.getItem("project_id"),
				stage_id: localStorage.getItem("stage_id"),
				group_id: this.group_id,*/
			};
			this.axios
				.get("https://admin.darts-l.com/api/odo/schedule", { params: params })
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data.teams));
					this.match_days = res.data.match_days;
					this.teams = res.data.teams;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/*.container {
	padding: 4rem 1rem;
	text-align: left;
}*/
.table-bordered {
	border: 1px solid #999;
	td,
	th {
		border: 1px solid #999;
		vertical-align: middle;
	}
}
th.venue {
	background-color: #eee;
}
span.venue_name {
	white-space: nowrap;
	font-size: 1rem;
	font-weight: normal;
}
span.team_name {
	white-space: nowrap;
	font-size: 0.8rem;
	font-weight: normal;
}
.btn-dl {
	font-size: 0.8rem;
	color: #333;
	border: none;
	background: $primary-color;
	cursor: pointer;
	margin: 2rem 0 0 0;
	@include sp {
		margin: 2rem 0 0 0;
	}
	&:hover {
		color: #333;
		background-color: $secondary-color;
	}
	&:active {
		color: #333;
		background-color: $secondary-color;
	}
}
</style>
